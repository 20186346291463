import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../../app/modules/auth";
import dateReducer from "../../app/modules/date/reducers/dateReducer";
import statisticDataReducer from "../../app/modules/date/reducers/statisticDataReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  date: dateReducer,
  data: statisticDataReducer
});

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()]);
}
