import React, { useEffect } from "react";
import { Redirect, Route, Switch, Link } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_start/helpers";
import axios from "axios";
import * as auth from './redux/AuthRedux';
import {login} from './redux/AuthCRUD';
import { useDispatch } from "react-redux";

export function AuthPage() {
  
  const dispatch = useDispatch();
  dispatch(auth.actions.logout());
  const authToken = new URLSearchParams(window.location.search).get('token');

  // const authToken = document.cookie.match('(^|;) ?' + 'token' + '=([^;]*)(;|$)') ? document.cookie.match('(^|;) ?' + 'token' + '=([^;]*)(;|$)')[2] : null;
  console.log("###");
  console.log(authToken);

  useEffect(()=>{
    dispatch(auth.actions.logout());
    console.log("###############################################");
  },[])


  useEffect(() => {
    document.body.classList.add("bg-white");

    if (authToken) {
      async function loginWithCookie () {
        const res = await axios.get('https://auth.360xcon.com/api/v1/auth/user/', {
          headers : { 'Authorization' : authToken }
        })
        if (res.statusText == "OK") {
          console.log(res.data.data);
          dispatch(auth.actions.login(authToken.slice(7)));
          setCookie('token', null, 1);
        } else {
          alert('새로 로그인해주세요.')
        }
      };
      loginWithCookie();
    }

    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  const setCookie = (name, value, exp) => {
    var date = new Date();
    date.setTime(date.getTime() + exp*24*60*60*1000);
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + '; domain=dtype-editor.360xcon.com/; path=/; SameSite=None; Secure;';
  }

  // setCookie(name, value, exp) {
  //   var date = new Date();
  //   date.setTime(date.getTime() + exp*24*60*60*1000);
  //   document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + '; domain=360xcon.com; path=/; SameSite=None; Secure;';
  // },

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid"
        id="kt_login"
      >
        {/* Aside */}
        <div
          style={{
            backgroundColor:'#ffbd89'
          }}
          className="d-flex flex-column flex-lg-row-auto w-lg-600px pt-15 pt-lg-0"
        >
          {/* Top */}
          <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
            {/* begin::Aside Logo */}
            <Link to="/" className="mb-6">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/360hexastudio_logo_dtype.png")}
                className="h-75px"
              />
            </Link>
            {/* end::Aside Logo */}

            {/* begin::Aside Subtitle */}
            <h3 className="fw-bolder fs-2x text-white lh-lg">
              오직 당신만을 위한 공간
              <br />
              지금 시작하세요
            </h3>
            {/* end::Aside Subtitle */}
          </div>

          {/* Bottom */}
          <div
            className="d-flex flex-row-fluid bgi-size-contain bgi-no-repeat bgi-position-y-bottom bgi-position-x-center min-h-350px"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/illustrations/login_image_xcon.png"
              )})`,
            }}
          ></div>
        </div>

        {/* Content */}
        <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-20 px-10 p-lg-7 mx-auto mw-450px w-100">
          {/* <div className="d-flex flex-column-fluid flex-center py-10"> */}
          <div className="flex-center py-10">
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Route path="/auth/registration" component={Registration} />
              {/* <Route path="/auth/forgot-password" component={ForgotPassword} /> */}
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          {/* <div className="d-flex justify-content-lg-start justify-content-center align-items-center py-7 py-lg-0">
            <span className="text-primary fw-bolder fs-4 cursor-pointer">
              Terms
            </span>
            <span className="text-primary ms-10 fw-bolder fs-4">Plans</span>
            <span className="text-primary ms-10 fw-bolder fs-4">
              Contact Us
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
}
