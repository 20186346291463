import React ,{useEffect} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useDispatch, useSelector } from 'react-redux'
import { RootState } from "../../../../../setup";
import { setExhibition } from '../../../date/actions/statisticDataAction';


export default function ExhibitionInput(props) {
  const exhibitionsObj : any = useSelector<RootState>((state) => state.auth.user?.exhibitions)
  // const exhibition = exhibitionsObj.data.exhibitionCode;
  // const [exhibition, setExhibition] = React.useState('');
  const {exhibitions, setExhibitions} = props;
  const dispatch = useDispatch();


  const handleChange = (event) => {
    dispatch(setExhibition(event.target.value))
    setExhibitions(event.target.value);
    
    // console.log(exhibitions);
  };

  useEffect(()=> {
    // console.log('exhibition in useEffect');
    // console.log(exhibitions);
    dispatch(setExhibition(exhibitions));
    // setExhibitions(exhibition);
  }, [exhibitions])

  const data = exhibitionsObj.data;
  // console.log('datatadajajajaj');
  // console.log(data);

  return (
    <Box sx={{ minWidth: 120, mt:3 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Exhibitions</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={exhibitions}
          label="Exhibition"
          onChange={handleChange}
        >
          {data.map((row:any) => 
            <MenuItem value={row.exhibitionCode}>{row.exhibitionCode}</MenuItem>    
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
