import React, {useEffect, useState,useContext} from "react";
import {
  EngageWidget1,
} from "../../../../../_start/partials/widgets";
import './StatisticStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import BoothVisits from "./BoothVisits";
import { Chart } from "react-google-charts";
import ExhibitionInput from "./ExhibitionSelect";
import SideMenuContext from '../../../../../app/context/sideMenu/SideMenuContext';
import { changeStartDate } from "../../../date/actions/dateAction";
import { RootState } from "../../../../../setup";
import { setData, setExhibition, setCountryLists } from "../../../date/actions/statisticDataAction";
import axios from "axios";
// app/context/sideMenu/SideMenuContext
// ';


// ChartJS.register(ArcElement, Tooltip, Legend);



export default function Statistics() {

  const [isChecked, setIsChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const sideMenuContext = useContext(SideMenuContext)
  const {switchSideMenu} = sideMenuContext
  const dispatch = useDispatch();
  const startDay = useSelector((state) => state.date.startDay);
  const endDay = useSelector((state) => state.date.endDay);
  const country = useSelector((state) => state.data.data.country);
  const exhibitionsObj : any = useSelector<RootState>((state) => state.auth.user?.exhibitions)
  const [exhibitions, setExhibitions] = useState(exhibitionsObj.data[0].exhibitionCode);
  console.log('code data6727272727272');
  console.log(exhibitionsObj);
  const [isData, setIsData] = useState({});
  const [boothClickData, setBoothClickData] = useState([]);
  const initialArr = ["Country", "Visits"];
  const [countryData, setCountryData] = useState([initialArr]);
  const [countryArr, setCountryArr] = useState([]);
  const [visitsArr, setVisitsArr] = useState([]);
  
  // date 문자열 끊기
  // 전시 선택 안 했을 때 안나오게 하기
  
  
  useEffect(() => {
    getExhibitionsData();
  }, [endDay,startDay, exhibitions]);
  
  
  
  
  const getExhibitionsData = async () => {
    const res = await axios.get(`https://mypage.360xcon.com/analytics/statistics/${exhibitions}?startDate=${startDay}&endDate=${endDay}`);
    setIsData(res.data.data.boothClick);
    dispatch(setData(res.data.data));
    setBoothClickData(res.data.data.boothClick);
    AddCountry(res.data.data.country);
    // 여기서 컨츄리 데이터를 따로 리듀서에 저장시키고 피디에프 컴포에서 불러다 쓰기만 하기 
    
  }
  // dispatch(setCountryLists(countryData));
    
    const AddCountry = (arr:any) => {
      for(var i = 0; i<arr.length; i++){
        var tempArr = [];
        tempArr = [arr[i]._id, arr[i].count];
        setCountryData([...countryData, tempArr]);
      }
    }
    

  const getFirstIndex = (arr:any) => {
      var arrtemp:any = []    
      arr.map((row:any) => 
      arrtemp = [...arrtemp, row[0]]
      )
      return (
        arrtemp.map((row:any) => 
        <div>
          {row}
        </div>
        )
      )
      
  }

  
  const getSecondIndex = (arr:any) => {
    var arrtemp2:any = []    
    arr.map((row:any) => 
    arrtemp2 = [...arrtemp2, row[1]]
    )
    return (
      arrtemp2.map((row:any) => 
      <div>
        {row}
      </div>
      )
    )
 
  }

  return (
    <div className="card">
         <div className="card-body">
        <EngageWidget1
          className="mb-5 mb-xxl-8"
          bgHex="#F3F6F9"
          lg="true"
          bgImage="svg/illustrations/copy.svg"
        />

                    

        <div className='chart-box'>
          <h1>전시 선택</h1>
          <ExhibitionInput exhibitions={exhibitions} setExhibitions={setExhibitions} />
        </div>

                         
        
        
         
        <div className='chart-box'>
          <h1>부스별 방문 데이터</h1>
          <div className="tiemvisit-chart-box">
          <BoothVisits boothData={boothClickData} />
          </div>         
        </div>


        <div className="chart-box">        
        <h1>국가별 방문자 세션</h1>
        <Chart
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 0) return;
                const region = countryData[selection[0].row + 1];
                
              },
            },
          ]}
          chartType="GeoChart"
          width="100%"
          height="400px"
          data={countryData}
        />

       {/* {mapArray(countryData)} */}
          
        <div className='country-visit-box'>
          <div className='country-box'>
            {countryData.length >1 ?  getFirstIndex(countryData) : null }
          </div>
          <div className='visits-box'>
          {countryData.length > 1 ?  getSecondIndex(countryData) : null }
          </div>
        </div>

        </div> 
        </div>
    </div>
  );
}


