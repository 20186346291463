import { SELECT_EXHIBITION, SWITCH_TO_EDIT, ADD_TO_SCROLL_ARRAY, TOGGLE_BIRDS_EYE_VIEW, ADD_QUERY_URL_DATE } from "../types";

export default (state, action) => {
    switch (action.type) {
        case SELECT_EXHIBITION:
            return {
                ...state,
                exhibitionIndex: action.payload.exhibitionIndex
            };
        case SWITCH_TO_EDIT:
            return {
                ...state,
                editMode: action.payload.editMode
            };
        case TOGGLE_BIRDS_EYE_VIEW:
            return {
                ...state,
                birdsEye: action.payload.birdsEye
            };
        case ADD_TO_SCROLL_ARRAY:
            return {
                ...state,
                elemToScrollTo: [...state.elemToScrollTo, action.payload.elem]
            };

        case ADD_QUERY_URL_DATE:
            return {
                ...state,
                date: action.payload.date
            };

        default:
            return state;
    }
};
