import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { login } from "../redux/AuthCRUD";
import { toAbsoluteUrl } from "../../../../_start/helpers";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  // email: "admin@demo.com",
  // password: "demo",
  password: "",
  email: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        login(values.email, values.password)
          // .then(({ data: { accessToken } }) => {
          .then(({ data: {status, success, message, data} }) => {
            setLoading(false);
            dispatch(auth.actions.login(data));
          })
          .catch((e) => {
            
            setLoading(false);
            setSubmitting(false);
            setStatus("The login detail is incorrect");
            alert('로그인 실패 \n 비밀번호를 확인해주세요')
          });
      }, 1000);
    },
  });

  return (
    <>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* begin::Title */}
        <div className="pb-lg-15">
          <h3 className="fw-bolder text-dark display-6">
            Welcome to&nbsp;
              <span className="symbol-label align-items-end">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/360xcon_logo.png")}
                  
                  style={{
                    height:'25px'
                  }}
                />
              </span>
          </h3>
        </div>
        {/* begin::Title */}

        {/* {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-lg-15 alert alert-info">
            <div className="alert-text ">
              Use credentials <strong>admin@demo.com</strong> and{" "}
              <strong>demo</strong> to sign in.
            </div>
          </div>
        )} */}

        {/* begin::Form group */}
        <div className="v-row mb-10 fv-plugins-icon-container">
          <label className="form-label fs-6 fw-bolder text-dark">Email</label>
          <input
            placeholder=""
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10 fv-plugins-icon-container">
          <div className="d-flex justify-content-between mt-n5">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">
              Password
            </label>

            {/* <Link
              to="/auth/forgot-password"
              className="text-primary fs-6 fw-bolder text-hover-primary pt-5"
              id="kt_login_signin_form_password_reset_button"
            >
              Forgot Password ?
            </Link> */}
          </div>
          <input
            type="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className="pb-lg-0 pb-5">
          <button
            type="submit"
            id="kt_login_signin_form_submit_button"
            className="btn fw-bolder fs-6 px-8 py-4 my-3 me-3"
            disabled={formik.isSubmitting || !formik.isValid}
            style={{
              backgroundColor:'#f5964b',
              color:"white"
            }}
          >
            {!loading && <span className="indicator-label">Sign In</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          
          <Link
            to="/auth/registration"
            className="btn fw-bolder fs-6 px-8 py-4 my-3 me-3"
            id="kt_login_signin_form_singup_button"
            style={{
              backgroundColor:'#ffe9d8',
              color:"#f5bf91"
            }}
          >
            회원가입
          </Link>
          {/* <button
            type="button"
            className="btn btn-light-primary fw-bolder px-8 py-4 my-3 fs-6 mr-3"
          >
            <img
              src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
              className="w-20px h-20px me-3"
              alt=""
            />
            Sign in with Google
          </button> */}
        </div>

        
        {/* end::Action */}
      </form>
      <div className="pb-lg-0 pb-5">
        <div
          style={{
            position: 'absolute',
            bottom: '3%',
            left: '23%'
        }}>
          <h3 className="d-inline">powered by</h3>
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/dataking_logo.png")}
            style={{
              position: "relative",
              width: "32%",
              bottom: "4px",
              left: "10px",
            }}
            
          />  
        </div>
        
      </div>
    </>
  );
}
