var now = new Date();	// 현재 날짜 및 시간
var oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));	// 한달 전
var initialStartDate = oneMonthAgo.toISOString();

var now2 = new Date();
var yesterday = new Date(now2.setDate(now2.getDate() - 1));
var initialEndDate = yesterday.toISOString();

const initialState = {
    startDay: initialStartDate.split('T')[0],
    endDay:initialEndDate.split('T')[0]
}



export default (state = initialState, action : any) => {
    const {type, payload} = action;
    switch (type) {
        case 'CHANGE_STARTDATE':
            return {
                startDay:payload,
                endDay: state.endDay
            }
        case 'CHANGE_ENDDATE':
            return {
                startDay:state.startDay,
                endDay: payload
            }
        default:
            return state;
    }
}