/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { Modal, Spinner } from "react-bootstrap-v5";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG, toAbsoluteUrl } from "../../../../../_start/helpers";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { resetPassword } from "../../../../modules/auth/redux/AuthCRUD";
import BabylonViewer from "../../../../../_start/layout/components/BabylonViewer/BabylonViewer"
// --------------------------------------------------------------------------------------------
type Props = {
  show: boolean;
  handleClose: () => void;
};

const spinnerStyle = {
  "left": "50%",
  "top": "50%",
  "zIndex": "9000",
  "position": "fixed",
  "width": "100px",
  "height": "100px",
  "margin": "-30px 0 0 -30px",
};

const BirdsEyeViewModal: React.FC<Props> = ({ show, handleClose }) => {
  
  const [loading, setLoading] = useState(false);

  const getloading = (bool) => {
    setLoading(bool)
  };

  return (
    <Modal
      // id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      // dialogClassName="modal-dialog-centered mw-600px h-auto"
      dialogClassName="modal-dialog modal-xl"
      show={show}
      onHide={handleClose}
      centered
      
    >
      <div className="container px-7 py-7">
        <div className="modal-header py-2 d-flex justify-content-end border-0">
          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-light-primary"
            onClick={handleClose}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotone/Navigation/Close.svg"
            />
          </div>
          {/* end::Close */}
        </div>
        <div className="modal-body">
          { loading ? <Spinner animation="border" variant="dark" style={spinnerStyle} /> : <></>}
          <BabylonViewer getloading={getloading}/>
        </div>
      </div>
    </Modal>
  );
};

export { BirdsEyeViewModal };
