const initialState = {
    data:{},
    selectedExhibition:'',
    countryLists : []
}



export default (state = initialState, action : any) => {
    const {type, payload} = action;
    switch (type) {
        case 'SET_DATA':
            return {
                data:payload,
                selectedExhibition: state.selectedExhibition,
                countryLists : state.countryLists
            }
        case 'SET_EXHIBITION':
            return {
                data: state.data,
                selectedExhibition:payload,
                countryLists : state.countryLists
            }
        case 'SET_COUNTRYLISTS' :
            return {
                data: state.data,
                selectedExhibition: state.selectedExhibition,
                countryLists: payload
            }
        default:
            return state;
    }
}