export const setData = (data:Object) => {
    return {
        type:'SET_DATA',
        payload:data
    }
}

export const setExhibition = (data:any) => {
    return {
        type:'SET_EXHIBITION',
        payload:data
    }
}

export const setCountryLists = (data:any) => {
    return {
        type:'SET_COUNTRYLISTS',
        payload:data
    }
}


