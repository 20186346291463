import React,{useState, useEffect} from 'react';
import styled from "styled-components";
import DatePicker from 'react-datepicker';
import {useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup';
import { changeEndDate, changeStartDate } from "../../../../app/modules/date/actions/dateAction";
import { Chart } from "react-google-charts";
import BoothVisits from "../../../../app/modules/apps/shop/pages/BoothVisits";
import './ComponentToPrintStyle.css';
import axios from 'axios';

export const ComponentToPrint = React.forwardRef((props, ref:any) => {
    const exhibitionsObj : any = useSelector<RootState>((state) => state.auth.user?.exhibitions)
    const totalVisits = useSelector((state) => state.data.data.visits);
    const boothVisits = useSelector((state) => state.data.data.boothClick);
    const selectedExhibition = useSelector((state) => state.data.selectedExhibition);
    const countryLists = useSelector((state) => state.data.countryLists);
    const country = useSelector((state) => state.data.data.country);
    const [exhibitions, setExhibitions] = useState('');
    const dispatch = useDispatch();
    const [boothClickData, setBoothClickData] = useState([]);
    const startDay = useSelector((state) => state.date.startDay);
    const endDay = useSelector((state) => state.date.endDay);
    const initialArr = ["Country", "Visits"];
    const [countryData, setCountryData] = useState([initialArr])
    // const [finalCountryData, setFianlCountryData] = useState<Array<>([]);
    

        const getExhibitionsData = async () => {
            const res = await axios.get(`https://mypage.360xcon.com/analytics/statistics/${selectedExhibition}?startDate=${startDay}&endDate=${endDay}`);
            AddCountry(res.data.data.country);
            console.log('bbbbbbbbbbbbbbbb')
            console.log(res.data.data.country);
            
            // if(countryData.length >0){
            //     // setCountryData(countryData.splice(0,1));
            //     setCountryData(delete countryData[0])
            // }

          }

        const AddCountry = (arr:any) => {

            for(var i = 0; i<arr.length; i++){
              var tempArr = [];
              tempArr = [arr[i]._id, arr[i].count];
              setCountryData([...countryData, tempArr]);
            }
            // tempArr2 = countryData.shift();
          }
          
          useEffect(() => {
              setBoothClickData(boothVisits);
              getExhibitionsData();
              if(countryData.length > 0){
                  setCountryData(countryData.splice(0,1));
              }
            //   getExhibitionsData()
            //   console.log('useEffect countryData')
            //   console.log(countryData);
        }, [selectedExhibition, startDay, endDay])

        

    return (
      <div ref={ref} style={{padding:'5rem'}}>
                            <div>
                                <div>
                                    <h1><span style={{fontSize:'2.5rem'}}>{selectedExhibition}</span> 전시 데이터</h1>
                                </div>
                            <div style={{display:'flex', justifyContent:'space-between', marginTop:'2rem'}}>
                                <div style={{display:'flex'}}>
                                    <div>
                                        <h2>시작 날짜 : {startDay}</h2>
                                    </div>
                                    <div style={{marginLeft:'2rem'}}>
                                        <h2>종료 날짜 : {endDay}</h2>
                                    </div>
                                </div>
                                <div style={{marginRight:'3rem'}}>
                                    {/* <div className="icon-box red">
                                        <i className="bi bi-person-fill"></i>
                                    </div> */}
                                    <div style={{display:'flex', marginRight:'5rem'}}>
                                        <h1>총 방문자 : </h1>
                                        <h1 className="font-extrabold mb-0">{totalVisits}</h1>
                                    </div>
                                </div>
                            </div>
                           
            </div>
            <div style={{width:'90%', marginTop:'4rem'}}>
                <h1>부스별 방문 데이터</h1>
                    <div style={{width:'77rem', height:'40rem'}}>
                    <BoothVisits boothData={boothVisits} />
                </div>         

                <div style={{width:'100%', height:'40rem', marginTop:'5rem'}}>        
                <h1>국가별 방문자 세션</h1>
            <div className="limiter">
		        <div className="container-table100">
                <div className="wrap-table100">
                    <div className="table100">
                        <table>
                            <thead>
                                <tr className="table100-head">
                                    <th className="column1">국가별</th>
                                    <th className="column2">방문자 수</th>
                                </tr>
                            </thead>
                            <tbody>
                                {countryData.map((row:any)=> 
                                <tr>
                                    {row.map((minirow:any)=> 
                                    <td>
                                        {minirow}
                                    </td>
                                    )}
                                </tr>
                                )  }
                                   
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>
            </div>

                </div> 
            </div>
      </div>
    );
  });