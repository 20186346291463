import React,{useRef, useEffect, useState} from 'react';
import type { ChartData, ChartArea } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top' as const,
//     },
//     title: {
//       display: true,
//       text: '부스별 방문 데이터',
//     },
//   },
// };

// const labels = ['1번', '2번','3번','4번','5번','6번','7번','8번','9번','10번',
//                 '11번','12번','13번','14번','15번','16번','17번','18번','19번','20번'];

const colors = [
  'red',
  'orange',
  'yellow',
  'lime',
  'green',
  'teal',
  'blue',
  'purple',
];
function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const colorStart = faker.random.arrayElement(colors);
  const colorMid = faker.random.arrayElement(
    colors.filter(color => color !== colorStart)
  );
  const colorEnd = faker.random.arrayElement(
    colors.filter(color => color !== colorStart && color !== colorMid)
  );

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: '방문자 수',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//   ],
// };

export default function BoothVisits(props:any) {

const chartRef = useRef<ChartJS>(null);
const {boothData} = props;
console.log('boothdata');
console.log(boothData);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '부스별 방문 데이터',
    },
  },
};

const labels = ['1번', '2번','3번','4번','5번','6번','7번','8번','9번','10번',
                '11번','12번','13번','14번','15번','16번','17번','18번','19번','20번'];

const data = {
  labels,
  datasets: [
    {
      label: '방문자 수',
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      // data: boothData,
      data : boothData ? Object.values(boothData) : null,
      backgroundColor: 'rgba(67, 94, 190, 0.6)',
    },
  ],
};







  return <Bar options={options} data={data} />;
}
