import React, { useReducer } from "react";
import axios from "axios";
import EditContext from "./EditContext";
import EditReducer from "./EditReducer";
import { SELECT_EXHIBITION, SWITCH_TO_EDIT, ADD_TO_SCROLL_ARRAY, TOGGLE_BIRDS_EYE_VIEW, ADD_QUERY_URL_DATE } from "../types";

export const EditState = (props) => {
    const initialState = {
        exhibitionIndex: 0,
        editMode: false,
        elemToScrollTo: [],
        birdsEye: false,
        urlQueryDate: ''
    };

    const [state, dispatch] = useReducer(EditReducer, initialState);

    const selectExhibition = (exhibitionIndex) => {
        
        dispatch({
            type: SELECT_EXHIBITION,
            payload: {
                exhibitionIndex
            },
        });
    };

    const startEditMode = (editMode) => {
        
        dispatch({
            type: SWITCH_TO_EDIT,
            payload: {
                editMode
            },
        });
    };

    const addUrlQueryDate = () => {
        
        dispatch({
            type: ADD_QUERY_URL_DATE,
            payload: {
                date: Date.now()
            },
        });
    };

    const toggleBirdsEyeView = () => {
        
        dispatch({
            type: TOGGLE_BIRDS_EYE_VIEW,
            payload: {
                birdsEye: !state.birdsEye,
            },
        });
    };

    const addToScrollArray = (elem) => {
        
        dispatch({
            type: ADD_TO_SCROLL_ARRAY,
            payload: {
                elem
            },
        });
    };

    return (
        <EditContext.Provider
            value={{
                exhibitionIndex: state.exhibitionIndex,
                editMode: state.editMode,
                elemToScrollTo: state.elemToScrollTo,
                birdsEye: state.birdsEye,
                urlQueryDate: state.urlQueryDate,
                selectExhibition,
                startEditMode,
                addToScrollArray,
                toggleBirdsEyeView,
                addUrlQueryDate
            }}
        >
            {props.children}
        </EditContext.Provider>
    );
};
