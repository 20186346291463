import React, {useState, useRef, useEffect} from "react";
import { AsideMenuItem } from "./AsideMenuItem";
import DatePicker from 'react-datepicker';  // DatePicker 라는 컴포넌트도 가져오깅
import {useDispatch, useSelector } from 'react-redux'
import styled from "styled-components";
import { RootState } from "../../../../setup";
import { changeEndDate, changeStartDate } from "../../../../app/modules/date/actions/dateAction";
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import "react-datepicker/dist/react-datepicker.css"; 	// 스타일 맥이기
import './AsideStatisticStyle.css';




export function AsideMenuStatistics() {
  const exhibitionsObj : any = useSelector<RootState>((state) => state.auth.user?.exhibitions)
  const exhibitions = exhibitionsObj.data;
  const dispatch = useDispatch();
  const startDay = useSelector((state) => state.date.startDay);
  const endDay = useSelector((state) => state.date.endDay);
  // const endDay = useSelector((state) => state.date.endDay);
  const totalVisits = useSelector((state) => state.data.data.visits);
  const selectedExhibition = useSelector((state) => state.data.data.selectedExhibition);
  var now = new Date();	// 현재 날짜 및 시간
  var oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));	// 한달 전
  var now2 = new Date();
  var yesterday = new Date(now2.setDate(now2.getDate() - 1));
  
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(yesterday);
  const MyDatePicker = styled(DatePicker)`
  width:90%;
  height:3rem;
  font-size:1.6rem;
  font-weight:bold;
  background-color:transparent;
  color:black;
  border: 1px solid;
  cursor:pointer;
  border-radius:2rem;
  text-align:center;
 ` 

 const handleStartDate = (date: Date) => {
   console.log(date.toISOString());
   var startDay = date.toISOString();
  setStartDate(date);
  dispatch(changeStartDate(startDay.split('T')[0]))
 }

 const handleEndDate = (date: Date) => {
   console.log(date);
   var endDay = date.toISOString();
   setEndDate(date);
   dispatch(changeEndDate(endDay.split('T')[0]));
}


const componentRef:any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      {" "}
      <>
        <>
          <div className="menu-item">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              Statistics
            </h4>
            <div className="date-pick-box">날짜 선택</div>
          </div>

                          <div>
                            <MyDatePicker 
                              selected={startDate} 
                              dateFormat="yyyy-MM-dd" // 날짜 형식
                              onChange={date => handleStartDate(date)} 
                              
                              />
                           
                          </div>
                          <div className='margin-top'>
                            <MyDatePicker 
                              selected={endDate} 
                              dateFormat="yyyy-MM-dd" // 날짜 형식
                              onChange={date => handleEndDate(date)} 
                              />
                           
                          </div>


                    <div className="card margin-top">
                        <div className="card-body px-3 py-4-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="icon-box red">
                                    <i className="bi bi-person-fill"></i>
                                    </div>
                                </div>
                                <div className="col-md-8 pad-top">
                                    <h6 className="text-muted font-semibold">총 방문자</h6>
                                    <h6 className="font-extrabold mb-0">{totalVisits}</h6>
                                </div>
                            </div>
                        </div>
                    </div>


                  <div className='none'><ComponentToPrint ref={componentRef} /></div>

                  <nav>
                  <ul>
                    <li onClick={handlePrint}>
                      PDF 저장 및 인쇄
                      <span></span><span></span><span></span><span></span>
                    </li>
                  </ul>                  
                  </nav>
        </>
      </>
    </>
  );
}

