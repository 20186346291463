export const changeStartDate = (date: any) => {
    return {
        type:'CHANGE_STARTDATE',
        payload:date
    }
}

export const changeEndDate = (date: any) => {
    return {
        type:'CHANGE_ENDDATE',
        payload:date
    }
}