import { useEffect, useRef, useState } from "react";
import * as BABYLON from 'babylonjs'
import 'babylonjs-loaders';
import { Button, AdvancedDynamicTexture } from 'babylonjs-gui';

const BlobUrl = "https://360hexaworld.blob.core.windows.net/glb-files-dev/"

let canvasstyle = {
    width: "100%",
    height: "100%",
	border: "1px solid #727272",
    border_radius: "0.4rem",
    position: "relative",
}

const buttonEvent = (scene, camera, camera1, button) => {
        const itEnded = function(test) {
            console.log("animation ended:", test);
        }
        const animationcamera = new BABYLON.Animation(
            "myAnimationcamera", 
            "position", 
            50, 
            BABYLON.Animation.ANIMATIONTYPE_VECTOR3, 
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        if (scene.activeCamera == camera) {
            var keys = []
            keys.push({
            frame: 10,
            value: camera.position,
            });
            keys.push({
            frame: 100,
            value: camera1.position
            });
            animationcamera.setKeys(keys);
            camera1.animations = [];
            camera1.animations.push(animationcamera);
            scene.beginAnimation(camera1, 0, 100, false, 1.2, itEnded);
            button.children[0].source = BlobUrl + "Person.png";
            scene.activeCamera = camera1;

        } else if (scene.activeCamera == camera1) {
            var keys = []
            keys.push({
            frame: 10,
            value: camera1.position,
            });
            keys.push({
            frame: 100,
            value: new BABYLON.Vector3(0, 20, 20)
            });
            animationcamera.setKeys(keys);
            camera.animations = [];
            camera.animations.push(animationcamera);
            scene.beginAnimation(camera, 0, 100, false, 1.2, itEnded);
            button.children[0].source = BlobUrl + "Display.png";
            scene.activeCamera = camera;
        }
    }

const createScene = (engine) => {
    const scene = new BABYLON.Scene(engine);
    scene.gravity = new BABYLON.Vector3(0, -0.9, 0);
    scene.collisionsEnabled = true;
    return scene;
}

const createLight = (scene) => {
    new BABYLON.HemisphericLight("hemiLight", new BABYLON.Vector3(0, 0, 1), scene);
    new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(0, 0, 1), scene);
}

const createCamera = (scene, canvas) => {
    const camera = new BABYLON.ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2, 30, new BABYLON.Vector3(0, 15, -2), scene);
    camera.attachControl(canvas, true);
    camera.wheelPrecision = 10;
    camera.minZ = 0.999;
    camera.lowerRadiusLimit = 2;
    camera.upperRadiusLimit = 40;
    camera.setTarget(new BABYLON.Vector3(0, 2, 0));
    camera.checkCollisions = true;
    camera.speed = 0.5;
    camera.angularSensibility = 4000;
    
    const camera1 = new BABYLON.UniversalCamera("MyCamera", new BABYLON.Vector3(0, 3, 0), scene);
    camera1.minZ = 0.1;
    camera1.attachControl(canvas, true);
    camera1.speed = 0.2;
    camera1.angularSpeed = 0.5;
    camera1.angle = Math.PI/2;
    camera1.setTarget(new BABYLON.Vector3(0, 2, -10));
    camera1.keysUp.push(87);
    camera1.keysLeft.push(65);
    camera1.keysDown.push(83);
    camera1.keysRight.push(68);
    camera1.checkCollisions = true;
    camera1.ellipsoid = new BABYLON.Vector3(0.5, 1, 0.5);
    camera1.ellipsoidOffset = new BABYLON.Vector3(0, 1, 0); 
    camera1.applyGravity = true;
    return [camera, camera1];
}

const createButton = (scene, camera, camera1) => {
    var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
    var button = Button.CreateImageOnlyButton("but", BlobUrl + "Display.png");
    button.left = "45%";
    button.top = "-42%";
    button.width = "50px";
    button.height = "40px";
    button.fontSize = 20;
    button.color = "transparent"
    advancedTexture.addControl(button);
    button.onPointerDownObservable.add(function(info) {
        buttonEvent(scene, camera, camera1, button);
    });
};

const createEnv = (scene, props) => {
    const skybox = BABYLON.MeshBuilder.CreateSphere('skyBox', { diameter: 10000, sideOrientation: 2 }, scene);
    skybox.infiniteDistance = true;
    skybox.applyFog = false;

    const env = new BABYLON.CubeTexture(BlobUrl + "environment.dds", scene); 
    var refleMaterial = new BABYLON.StandardMaterial("mat", scene);
    refleMaterial.backFaceCulling = true;
    refleMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
    refleMaterial.specularColor = new BABYLON.Color3(1, 1, 1); 
    refleMaterial.reflectionTexture = env;

    BABYLON.SceneLoader.ImportMesh("", BlobUrl, "Dtype_texture01.glb", scene, function (meshes) {  
        const cannon = meshes[0]
        for (var u = 0; u < cannon.getChildMeshes().length; u++) {                
            cannon.getChildMeshes()[u].material.reflectionTexture = env;  
        };
        
        meshes.map((mesh) => {
            mesh.checkCollisions = true;
        });
        props.getloading(false);
    });
}

const BabylonViewer = (props) => {
    
    const canvasRef = useRef(null)
    
    useEffect( () => {
        const canvas = canvasRef.current;
        const engine = new BABYLON.Engine(canvas, true);
        const scene = createScene(engine);
        const [camera, camera1] = createCamera(scene);
        createLight(scene);
        createButton(scene, camera, camera1);
        createEnv(scene, props);

        engine.runRenderLoop(function () {
            scene.render();
        });

        props.getloading(true);

    }, []);
    
        return <canvas style={canvasstyle} ref={canvasRef} />
    };          
    
export default BabylonViewer;